import * as React from 'react';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import useOfTwoLanguages from '../../../images/educational-corner/blog16/Upotreba_dvaju_jezika_istovremeno.png';
import verticalBilinguality from '../../../images/educational-corner/blog16/Vertikalna-dvojezicnost.png';

const BilingualismAtChildren = () => {
  return (
    <PageNew
      title="KOKOLINGO - Dvojezičnost kod djece"
      description="Pojam dvojezičnosti vrlo se različito definira, nema jednostavne i 
      općeprihvaćene odrednice. Dvojezičnost se prije određivala prema idealnome govorniku 
      obaju jezika. No na dvojezičnost se više ne gleda u okviru uske definicije koja uključuje 
      ujednačeno jezično znanje i usporednu dvojezičnost praktički od rođenja."
      headline="Dvojezičnost kod djece - razlog za brigu ili prednost?"
    >
      <Text as="p">
        Pojam dvojezičnosti vrlo se različito definira, nema jednostavne i
        općeprihvaćene odrednice. Dvojezičnost se prije određivala prema
        idealnome govorniku obaju jezika. No na dvojezičnost se više ne gleda u
        okviru uske definicije koja uključuje ujednačeno jezično znanje i
        usporednu dvojezičnost praktički od rođenja.{' '}
        <b>
          Dvojezičnom populacijom danas nazivamo osobe koje se služe dvama
          jezicima na razini dovoljnoj za napredno sporazumijevanje.
        </b>{' '}
        S obzirom na dominaciju engleskog jezika u svijetu te mobilnost
        svjetskog stanovništva, možemo reći da je više ljudi dvojezično nego
        jednojezično. Prema nekim procjenama, smatra se da je od 50 do 70% ljudi
        na svijetu dvojezično (odnosno višejezično).
      </Text>
      <Text as="h2">Podjela dvojezičnosti</Text>
      <Text as="p">
        Dvojezičnost možemo podijeliti prema više kriterija (ovdje se navode
        samo neki).
      </Text>
      <Text as="p">
        1. Prema vremenu stjecanja drugoga jezika, postoje{' '}
        <b>rana dvojezičnost</b> koja se stječe u dobi do 11-12 godina, a{' '}
        <b>kasna</b> nakon toga.
      </Text>
      <Text as="p">
        Kada djeca usvajaju oba jezika od rođenja, govori se o{' '}
        <b>istodobnoj (simultanoj) dvojezičnosti</b>, a kada drugim jezikom
        ovladavaju nakon materinskog (iza 3. godine), govori se o{' '}
        <b>slijednoj (sukcesivnoj) dvojezičnosti.</b>
      </Text>
      <Text as="p">
        2. Najbolji je primjer dvojezičnosti kada su jezici dovoljno različiti
        da ih svatko smatra dvama jezicima (npr. jezici iz različitih država,
        talijanski i švedski). To je <b>horizontalna vrsta dvojezičnosti</b>.
      </Text>
      <Text as="p">
        Iako je odnos među materinskim idiomom i standardnim jezikom po nečemu
        svojstven i različit od odnosa dvaju jezika, ipak je često po mnogočemu
        sličan odnosu među dvama jezicima. Tako se pojedini hrvatski mjesni
        idiomi (npr. kajkavski i čakavski) više razlikuju od standarda nego neki
        slavenski jezici međusobno. Ta se vrsta dvojezičnosti naziva{' '}
        <b>vertikalnom dvojezičnošću</b> (idiomi ili varijacije istog jezika
        unutar iste države).
      </Text>
      <BlogImage
        imgSrc={verticalBilinguality}
        imgAlt="Vertikalna dvojezičnost"
        imgTitle="Vertikalna dvojezicnost"
      />
      <Text as="p">
        3. Prema jezičnim sposobnostima u oba jezika postoji{' '}
        <b>uravnotežena dvojezičnost</b> u kojoj osobe podjednako vladaju obama
        jezicima i <b>neuravnotežena dvojezičnost</b>, u kojoj je jedan jezik
        primjetno jači, a drugi slabiji. U ovom kontekstu upotrebljavamo pojam{' '}
        <b>dominantnog</b> jezika (bolje poznavani jezik).
      </Text>
      <Text as="p">
        Većina istraživanja koja potvrđuju dvojezične prednosti temelji se na
        proučavanju dvojezičnih govornika koji se smatraju ujednačenima.
      </Text>
      <Text as="h2">Jednaka izloženost i upotreba dvaju jezika istodobno?</Text>
      <Text as="p">
        Zanimljivi su primjeri kada djeca odrastaju uz roditelje koji su
        govornici dvaju različitih jezika i kod njih se može na prvu očekivati
        da će usporedno jednako usvajati dva jezika. No čini se da je potpuno
        jednaka izloženost dvama jezicima od rođenja, i to tijekom nekog duljeg
        razdoblja gotovo nemoguća.
      </Text>
      <Text as="p">
        Uzmimo za primjer izmišljenog malog dječaka Vasilija koji je sin
        Mađarice i Rusa, a trenutačno žive u Mađarskoj.
      </Text>
      <Text as="p">
        Kao prvo, u većini zapadnjačkih kultura majke su znatno više sa svojim
        dojenčetom nego očevi, kojima nije omogućen valjani roditeljski dopust.
        Dakle, Vasilij će znatno više slušati, odnosno usvajati mađarski od
        ruskog jezika.
      </Text>
      <Text as="p">
        Zatim, čim krene predškolski odgoj s 12 mjeseci, veći dio dana opet će
        biti izložen samo mađarskom jeziku. Mađarskim je bolje ovladao nego
        ruskim jer je s ocem imao priliku razgovarati tek kada bi se navečer
        vratio s posla. S njegovih 18 godina obitelj se seli u Rusiju gdje
        Vasilij kreće na studij koji se, naravno, održava na ruskom. Do kraju
        studija češće i bolje služi se ruskim nego mađarskim jezikom.
      </Text>
      <Text as="p">
        Tu govorimo o teorijskom konceptu <b>jezičnih domena</b>. Domene su
        svojevrsna područja u kojima upotrebljavamo jezik (u školi, na poslu, u
        obitelji, tijekom religijskih svečanosti itd.), a za dvojezične je
        govornike karakteristično da imaju barem određen dio domena u kojima se
        uglavnom ili isključivo rabi samo jedan jezik.
      </Text>
      <Text as="h2">Usvajanje jezika kod istodobno dvojezične djece</Text>
      <Text as="p">
        Sada kada smo se upoznali s osnovnim pojmovima vezanima uz dvojezičnost,
        možemo se pobliže pozabaviti djecom kao što je Vasilij - istodobno
        dvojezičnom djecom.
      </Text>
      <Text as="p">
        Ta djeca prolaze dvije faze usvajanja jezika.{' '}
        <b>
          Prva faza obuhvaća nediferenciran jednojezični sustav u kojem su
          prisutni elementi obaju jezika, a u drugoj fazi oba jezika počinju
          funkcionirati kao zasebni sustavi.
        </b>
      </Text>
      <Text as="p">
        Važno je naglasiti i da se{' '}
        <b>
          isti razvojni procesi koji se javljaju kod jednojezičnog usvajanja
          jezika javljaju i kod dvojezičnog usvajanja, samo u dvama jezicima.
        </b>
      </Text>
      <BlogImage
        imgSrc={useOfTwoLanguages}
        imgAlt="Upotreba dvaju jezika istovremeno"
        imgTitle="Upotreba dvaju jezika istovremeno"
      />
      <Text as="p">
        Ukratko:
        <ul>
          <li>
            <b>Od 1. do 2. godine</b> - jezici su još nediferencirani pa se
            javlja <b>spajanje jezika</b>, što znači da su dijelovi riječi iz
            jednog jezika spojeni s dijelovima riječi iz drugog jezika,
            primjerice riječ <i>playati</i>, koja dolazi od engleske riječi{' '}
            <i>play</i> i hrvatske <i>(igr)ati</i>. Dakle, korijen riječi može
            pripadati engleskom jeziku, a prefiks ili sufiks hrvatskome.
          </li>
          <li>
            <b>Od 2. do 3. godine</b> - javlja se miješanje jezika, što znači da
            se riječi obaju jezika upotrebljavaju u istoj rečenici ili se
            gramatika jednog jezika prenosi u drugi, primjerice{' '}
            <i>Idemo igrati basketball</i>.
          </li>
          <li>
            <b>S 4 godine</b> - dijete ulazi u drugu fazu usvajanja jezika, što
            znači da počinje upotrebljavati oba jezika kao{' '}
            <b>zasebne sustave</b>.
          </li>
        </ul>
      </Text>
      <Text as="p">
        Unatoč čestoj pojavi spajanja i miješanja jezika u prve tri godine
        života, novija istraživanja upućuju na to da dvojezična djeca već u
        fazama jedne i dvije riječi mogu odvojeno i prikladno upotrebljavati oba
        jezika i prilagoditi količinu miješanja koda (eng. code-mixing), odnosno
        jezika s obzirom na okolinu u kojoj se nalaze (s mamom Amerikankom
        govorit će češće engleski, a s ocem Hrvatom češće hrvatski).
      </Text>
      <Text as="p">
        To nam sugerira da već u vrlo ranoj dobi dvojezična djeca posjeduju
        metalingvističku svijest o postojanju dvaju zasebnih jezičnih sustava
        koja mogu uspješno razlikovati.
      </Text>
      <Text as="h2">
        Brzina usvajanja jezika kod istodobno dvojezične djece
      </Text>
      <Text as="p">
        Istraživanja su pokazala da se{' '}
        <b>
          prve riječi kod istodobno dvojezičnih govornika javljaju oko 12.-13.
          mjeseca života, otprilike u isto vrijeme kada se javljaju i kod
          jednojezičnih govornika
        </b>
        . Također,{' '}
        <b>
          dvojezični govornici usvajaju vokabular otprilike istim tempom kao i
          jednojezični ako uzimamo u obzir oba jezika koja usvajaju
        </b>{' '}
        te je raspodjela usvojenosti leksičkih kategorija (npr. imenice,
        glagoli, pridjevi itd.) u njihovim ranim leksicima slična. Jednojezični
        govornici usvajaju nove riječi principom međusobnog isključivanja, što
        znači da se nove riječi odnose na nove pojmove. Dvojezična djeca
        proizvode riječi istog značenja u drugom jeziku od trenutka kad
        progovore i u prosjeku kodiraju 30% riječi u oba jezika u ranim
        (vokabular od 2 do 12 riječi) i kasnijim (do 500 riječi) fazama razvoja
        te autori smatraju da je to pokazatelj da posjeduju dva odijeljena
        leksička, odnosno rječnička sustava.
      </Text>
      <Text as="p">
        <b>
          Ako je dijete usvojilo manje od 20 riječi ukupno u oba jezika do 20.
          mjeseca života, smatra se da bi moglo imati jezičnu teškoću te se
          preporučuje savjetovanje s logopedom.{' '}
        </b>
      </Text>
      <Text as="h2">Dvojezičnost kod djece – prednost!</Text>
      <Text as="p">
        Dugo se smatralo da dvojezičnost kod djece ima negativne posljedice i da
        istodobno učenje dvaju jezika može uzrokovati sporiji razvoj i
        nepotrebno opteretiti dijete.
      </Text>
      <Text as="p">
        Djeca koja odrastaju s dvama jezicima možda posuđuju iz jednog jezika
        dok govore drugi, ali imaju metalingvističku svijest o postojanju dvaju
        sustava. Dvojezična djeca ubrzo nakon rođenja mogu razlikovati svoja dva
        jezika, a ta sposobnost razlikovanja obuhvaća i razlikovanje
        neakustičkih odlika jezika.
      </Text>
      <Text as="p">
        <b>
          Za razliku od odraslih dvojezičara, djeca nakon početne semantički
          ustrojene faze razdvajaju sustave dvaju jezika i ne miješaju riječi ni
          sintaksu (pravila slaganja rečenica).
        </b>
      </Text>
      <Text as="p">
        Dvojezičnost se danas, u odnosu na prije, smatra jezičnom pojavom koja
        govornicima donosi <b>niz prednosti</b>, šire od same činjenice
        poznavanja dvaju jezika: od kognitivnih,{' '}
        <b>preko društvenih i psiholoških do ekonomskih</b>. Većinom se i
        potvrđuje da{' '}
        <b>
          najviše dvojezičnih prednosti imaju baš ujednačeni jezični govornici
        </b>
        . Istraživanja također potvrđuju{' '}
        <b>
          neke prednosti dvojezičnih govornika u području metajezične
          svjesnosti, ovladavanju čitanjem i pisanjem te drugim jezičnim
          zadatcima (pripovjedne sposobnosti, opisivanje slike, imenovanje) u
          različitim jezicima
        </b>
        .
      </Text>
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Hržica, G., Brdarić, B. i Roch, M. (2015). Dominantnost jezika
            dvojezičnih govornika talijanskog i hrvatskog jezika.{' '}
            <i>Logopedija</i>, 5 (2), 34-40.
          </li>
          <li>
            Jelaska i sur. (2005). <i>Hrvatski kao drugi i strani jezik</i>.
            Zagreb: Hrvatska sveučilišna naklada.
          </li>
          <li>
            Špiranec, I. (2010). Tko se boji dvojezičnosti još (definiranje
            dvojezičnosti)?.{' '}
            <i>Strani jezici: časopis za unapređenje nastave stranih jezika</i>,
            39, 1-2, 9-28.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default BilingualismAtChildren;
